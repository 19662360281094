@import '/styles/vars';

.container {
    position: fixed;
    top: 0;
    pointer-events: none;
    padding: $gap36 $gap24;
    width: 100%;
    left: 0;
    z-index: 1000;
}

.icon {
    color: $color_yellow;
    margin: 0 $gap12 0 0;
}

.x {
    margin: 0 0 0 auto;
    cursor: pointer;
    svg {
        padding: 3px;
    }
}

.toast {
    display: flex;
    position: relative;
    pointer-events: all;
    max-width: 504px;
    width: 100%;
    border-radius: $radius2;
    background: #fff;
    box-shadow: 0 0 $gap48 0 rgba(21, 55, 56, 0.2);
    margin: 0 auto $gap18 auto;
    padding: $gap18 $gap18 $gap18 $gap30;
    transition: transform $ease, opacity $ease;
    animation: show $ease;
    word-break: break-word;
    &:after {
        content: '';
        position: absolute;
        left: $gap12;
        top: $gap12;
        bottom: $gap12;
        width: 4px;
        border-radius: 2px;
        background: $color_yellow;
    }
}

.warning {
    &:after {
        background: $color_red;
    }
    .icon {
        color: $color_red;
    }
}

.success {
    &:after {
        background: $color_green;
    }
    .icon {
        color: $color_green;
    }
}

@keyframes show {
    0% {
        opacity: 0;
        transform: translateY(-24px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
